<template lang="html">
<v-container>
  <v-card icon="mdi-account" >
		<v-card-text>
		<h1 class="display-2 my-2">{{ isUmpanBalik == true ? 'Isi Umpan Balik Ekspektasi Perilaku Rekan Kerja Anda' : 'Isi Ekspektasi Perilaku Rekan Kerja Anda'}}</h1>
		<hr>
		</v-card-text>
    <!-- <template v-if="ariaReadonly"> -->
    <!-- <v-row v-if="isUmpanBalik" class="mt-5">
      <v-col v-for="i in [1]" :key="i">
        <v-skeleton-loader
        class="mx-auto"
        min-width="300"
        type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row> -->
    <template>
      <v-form :ref="isUmpanBalik == true ? 'formUmpanBalikEkspektasiPerilaku' : 'formIsiEkspektasiPerilaku'">
      <v-row class="mt-1">
        <v-col align="center" cols="12">
          <base-material-card
              class="v-card-profile"
              :avatar="dataEkspektasiBawahan.foto"
              style="width:270px;margin-top:40px"
            >
            <v-card-text class="text-center" height="fit-content">
              <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
                {{dataEkspektasiBawahan.nama}}
              </h4>
              <h4 class="font-weight-light grey--text mb-2">
                NIP. {{dataEkspektasiBawahan.nip}}
              </h4>
              <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                {{dataEkspektasiBawahan.jabatan}}
              </h4>
              <div class="mt-5">
              </div>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
      <br>
      <br>
      <v-row>
        <v-col align="center" cols="12">
          <h1 style="font-family:Segoe UI;"> {{triwulan == 'tahun' ? 'Periode: Tahunan' : 'Periode: Triwulan '+triwulan}}</h1>
        </v-col>
      </v-row>
      <br>
      <v-row class="mt-1">
        <v-col cols="1">
        </v-col>
        <v-col align="center" cols="11">
          <v-row>
            <v-col cols=4 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>Ber</b>orientasi Pelayanan</h3>
              <label style="font-family:Segoe UI">- Memahami dan memenuhi kebutuhan masyarakat.</label>
              <br>
              <label style="font-family:Segoe UI">- Ramah, cekatan, solutif, dan dapat diandalkan.</label>
              <br>
              <label style="font-family:Segoe UI">- Melakukan perbaikan tiada henti.</label>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.e_berorientasi_pelayanan" :rules="isUmpanBalik == true ? [] : [rules.required]" :disabled="isUmpanBalik == true ? true : false" label="Ekspektasi Anda Terkait Berorientasi Pelayanan" outlined :auto-grow="autoGrow" :no-resize="noResize" height="fit-content"></v-textarea>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.r_berorientasi_pelayanan" :validate-on-blur="true" :rules="isUmpanBalik == true ? [rules.required] : []" :disabled="isUmpanBalik == true ? false : true" label="Umpan Balik Anda" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols=4 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>A</b>kuntabel</h3>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan jujur, bertanggung jawab, cermat, disiplin, dan berintegritas tinggi.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggunakan kekayaan dan barang milik negara secara bertanggung jawab, efektif, dan efisien.</label>
              <br>
              <label style="font-family:Segoe UI">- Tidak menyalahgunakan kewenangan jabatan.</label>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.e_akuntabel" :rules="isUmpanBalik == true ? [] : [rules.required]" :disabled="isUmpanBalik == true ? true : false" label="Ekspektasi Anda Terkait Akuntabel" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.r_akuntabel" :validate-on-blur="true" :rules="isUmpanBalik == true ? [rules.required] : []" :disabled="isUmpanBalik == true ? false : true" label="Umpan Balik Anda" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>K</b>ompeten</h3>
              <label style="font-family:Segoe UI">- Meningkatkan kompetensi diri untuk menjawab tantangan yang selalu berubah.</label>
              <br>
              <label style="font-family:Segoe UI">- Membantu orang lain belajar.</label>
              <br>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan kualitas terbaik.</label>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.e_kompeten" :rules="isUmpanBalik == true ? [] : [rules.required]" :disabled="isUmpanBalik == true ? true : false" label="Ekspektasi Anda Terkait Kompeten" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.r_kompeten" :validate-on-blur="true" :rules="isUmpanBalik == true ? [rules.required] : []" :disabled="isUmpanBalik == true ? false : true" label="Umpan Balik Anda" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>H</b>armonis</h3>
              <label style="font-family:Segoe UI">- Menghargai setiap orang apapun latar belakangnya.</label>
              <br>
              <label style="font-family:Segoe UI">- Suka menolong orang lain.</label>
              <br>
              <label style="font-family:Segoe UI">- Membangun lingkungan kerja yang kondusif.</label>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.e_harmonis" :rules="isUmpanBalik == true ? [] : [rules.required]" :disabled="isUmpanBalik == true ? true : false" label="Ekspektasi Anda Terkait Harmonis" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.r_harmonis" :validate-on-blur="true" :rules="isUmpanBalik == true ? [rules.required] : []" :disabled="isUmpanBalik == true ? false : true" label="Umpan Balik Anda" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>L</b>oyal</h3>
              <label style="font-family:Segoe UI">- Memegang teguh ideologi Pancasila, Undang-Undang Dasar Negara Republik Indonesia 1945, NKRI serta pemerintahan yang sah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga nama baik sesama ASN, Pimpinan, Instansi, dan Negara.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga rahasia jabatan dan negara.</label>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.e_loyal" :rules="isUmpanBalik == true ? [] : [rules.required]" :disabled="isUmpanBalik == true ? true : false" label="Ekspektasi Anda Terkait Loyal" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.r_loyal" :validate-on-blur="true" :rules="isUmpanBalik == true ? [rules.required] : []" :disabled="isUmpanBalik == true ? false : true" label="Umpan Balik Anda" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>A</b>daptif</h3>
              <label style="font-family:Segoe UI">- Cepat menyesuaikan diri menghadapi perubahan.</label>
              <br>
              <label style="font-family:Segoe UI">- Terus berinovasi dan mengembangkan kreativitas.</label>
              <br>
              <label style="font-family:Segoe UI">- Bertindak proaktif.</label>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.e_adaptif" :rules="isUmpanBalik == true ? [] : [rules.required]" :disabled="isUmpanBalik == true ? true : false" label="Ekspektasi Anda Terkait Adaptif" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.r_adaptif" :validate-on-blur="true" :rules="isUmpanBalik == true ? [rules.required] : []" :disabled="isUmpanBalik == true ? false : true" label="Umpan Balik Anda" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4 align="left">
              <h3 style="font-family:Segoe UI;color:darkred" class="my-2"><b>K</b>olaboratif</h3>
              <label style="font-family:Segoe UI">- Memberi kesempatan kepada berbagai pihak untuk berkontribusi.</label>
              <br>
              <label style="font-family:Segoe UI">- Terbuka dalam bekerja sama untuk menghasilkan nilai tambah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggerakkan pemanfaatan berbagai sumber daya untuk tujuan bersama.</label>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.e_kolaboratif" :rules="isUmpanBalik == true ? [] : [rules.required]" :disabled="isUmpanBalik == true ? true : false" label="Ekspektasi Anda Terkait Kolaboratif" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
            <v-col cols=3>
              <v-textarea v-model="dataEkspektasiBawahan.r_kolaboratif" :validate-on-blur="true" :rules="isUmpanBalik == true ? [rules.required] : []" :disabled="isUmpanBalik == true ? false : true" label="Umpan Balik Anda" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-form>
    </template>
    <v-card-text v-if="isUmpanBalik == true">
      <hr>
      <v-row class="mt-1">
        <!-- <v-col cols="1">
        </v-col> -->
        <v-col align="left" class="ml-16">
          <div>
            <h3 class="label font-weight-light"><strong>Rating Perilaku Kerja Periodik</strong></h3>
            <br>
            <p>Pejabat Penilai Kinerja mempertimbangkan seluruh umpan balik yang diterima pegawai beserta data dukung yang relevan atas perilaku kerja pegawai dan menetapkan rating hasil kerja pegawai dalam kategori:</p>
            <v-radio-group v-model="dataEkspektasiBawahan.bobot" column :rules="[rules.required]">
              <v-radio color="red" value="1">
                <template v-slot:label>
                  <strong class="red--text">Di bawah Ekspektasi</strong>
                </template>
              </v-radio>
              <v-radio color="primary" value="2">
                <template v-slot:label>
                  <strong class="primary--text">Sesuai Ekspektasi</strong>
                </template>
              </v-radio>
              <v-radio color="success" value="3">
                <template v-slot:label>
                  <strong class="success--text">Di atas Ekspektasi</strong>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
		<v-card-actions class="d-flex justify-center">
      <v-btn @click="closeEvent()" color="warning">tutup</v-btn>
      <v-btn v-if="isUmpanBalik == true" @click="simpanResponEkspektasiPerilakuKerja()" color="primary">Simpan Umpan Balik</v-btn>
      <v-btn v-else @click="simpanResponEkspektasiPerilakuKerja()" color="primary">Simpan Ekspektasi</v-btn>
    </v-card-actions>
  </v-card>

	<v-dialog v-model="dialogFormEkspektasiPerilaku" persistent>
    <!-- <FormEkspektasiPerilakuBawahan ref="formDataEkspektasiBawahan" :dataPegawaiBawahan="dataPegawaiBawahan" :dataEkspektasiBawahan="dataEkspektasiBawahan" @onClose="(x)=>{dialogFormEkspektasiPerilaku=x}"/> -->
  </v-dialog>
</v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'
// import FormEkspektasiPerilakuBawahan from './FormEkspektasiPerilakuBawahan.vue'
import {defaultRules} from '@/utils/lib'

  export default {
		components:{
			// FormEkspektasiPerilakuBawahan
		},
    props:{
			dataEkspektasiBawahan:Object,
      isUmpanBalik: Boolean,
      triwulan: String
		},
    data() {
      return {
        rules:{
          ...defaultRules
        },
        // dataPegawaiBawahan: null,
        loadingEl:-1,
        dialogFormEkspektasiPerilaku: false,
        disabled_ekskpektasi: false,
        respon: false,
        autoGrow: false,
        noResize: true,
        di_bawah_ekspektasi: true,
        di_atas_ekspektasi: true,
        sesuai_ekspektasi: true,
      };
    },
		watch:{
		},
    methods: {
			closeEvent(){
				this.$emit('onClose',false)
			},
      simpanResponEkspektasiPerilakuKerja(){
        var date = new Date()
        if(this.isUmpanBalik){
          if (this.$refs.formUmpanBalikEkspektasiPerilaku.validate()){
            this.respon = true
            this.dataEkspektasiBawahan.respon = this.respon
            this.loadingBtn = true
            this.dataEkspektasiBawahan.bulan = date.getMonth() + 1
            this.dataEkspektasiBawahan.triwulan = this.dataEkspektasiBawahan.jenis
            this.dataEkspektasiBawahan.tahun = this.$store.getters["user/data"].tahun
            SKPService.storeEkspektasiPerilakuKerja(this.dataEkspektasiBawahan).then(response => {
              let res = response.data
              if (res.success){
                store.commit('snackbar/setSnack',{message: 'Umpan Balik Ekspektasi Perilaku Berhasil Disimpan!', color:'success'})
              } else {
                if(res.message){
                  store.commit('snackbar/setSnack',{message: res.message +' Umpan Balik Ekspektasi Perilaku Gagal Disimpan.', color:'error'})
                }else{
                  store.commit('snackbar/setSnack',{message: 'Umpan Balik Ekspektasi Perilaku Gagal Disimpan.', color:'error'})
                }
              }
            }).finally(()=>{
              this.loadingBtn=false
              this.closeEvent()
            })
          }
        }else{
          if (this.$refs.formIsiEkspektasiPerilaku.validate()){
            this.respon = false
            this.dataEkspektasiBawahan.respon = this.respon
            this.loadingBtn = true
            this.dataEkspektasiBawahan.bulan = date.getMonth() + 1
            this.dataEkspektasiBawahan.triwulan = this.dataEkspektasiBawahan.jenis == 'tahun' ? 'tahun' : this.dataEkspektasiBawahan.jenis
            this.dataEkspektasiBawahan.tahun = this.$store.getters["user/data"].tahun
            SKPService.storeEkspektasiPerilakuKerja(this.dataEkspektasiBawahan).then(response => {
              let res = response.data
              if (res.success){
                store.commit('snackbar/setSnack',{message: 'Ekspektasi Perilaku Berhasil Disimpan!', color:'success'})
              } else {
                if(res.message){
                  store.commit('snackbar/setSnack',{message: res.message +' Ekspektasi Perilaku Gagal Disimpan.', color:'error'})
                }else{
                  store.commit('snackbar/setSnack',{message: 'Ekspektasi Perilaku Gagal Disimpan.', color:'error'})
                }
              }
            }).finally(()=>{
              this.loadingBtn=false
              this.closeEvent()
            })
          }
        }
      }
    },
  }
</script>
